#services {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
}

#services > h1 {
    position: relative;
    font-size: 2.5rem;
    color: var(--highlight);
    overflow-x: visible;
}

#services > h1::before {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 10px;
    transform: translateX(-15%);
    width: 150%;
    height: 15px;
    background-image: url("../../media/images/textUnderline.png");
    background-size: contain;
    background-repeat: no-repeat;
}

#services > div {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    gap: 20px; /* space between grid items */
    max-width: 1200px; /* optional, to control grid container width */
    width: 100%;
    margin-top: 20px;
    padding: 50px;
}

#services > div > div {
    /* optional, for visual separation */
    background-color: #fffefe;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

#services > div > div h1 {
    font-size: 3rem; /* adjust as needed */
    margin-bottom: 10px;
}

#services > div > div h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

#services > div > div p {
    font-size: 1rem;
    color: #555;
}
#services > div > div a {
    color: var(--highlight);
}
@media (max-width: 720px) {
    #services > div > div h2 {
        font-size: 1rem;
    }
}
@media (max-width: 600px) {
    #services > div > div h1 {
        font-size: 2rem;
        margin-bottom: 10px;
    }
}
@media (max-width: 720px) {
    #services > div > div h2 {
        font-size: 1.5rem;
    }
    #services > div > div h1 {
        font-size: 2rem;
        margin-bottom: 10px;
    }
    #services > div {
        display: flex;
        flex-direction: column;
    }
}
@media (max-width: 400px) {
    #services > div > div h2 {
        font-size: 1rem;
    }
}
