footer {
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--highlight_fade);
    padding: 20px;
}
footer div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
}
footer div + div {
    align-items: end;
    flex-direction: column;
}
footer div * {
    font-size: 1rem;
}
footer div + div hr {
    width: 100%;
    border: 1px solid #000;
}
footer div + div h2 {
    direction: ltr;
}
@media (max-width: 900px) {
    footer div * {
        font-size: 0.7rem;
    }
}
@media (max-width: 500px) {
    footer div {
        flex-direction: column;
        gap: 5px;
        border-left: 1px solid #000;
        padding: 5px;
        overflow-x: visible;
    }
    footer div + div {
        border: none;
    }
}
