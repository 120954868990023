@font-face {
    font-family: "NotoSans";
    src: url("./media/fonts/Noto_Sans_Hebrew/NotoSansHebrew-VariableFont_wdth\,wght.ttf");
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "NotoSans";
    direction: rtl;
    scroll-behavior: smooth;
}
*:not(h1, h2, button, li, header, .uwy *) {
    overflow-x: hidden;
}

#contactBtn a,
#back a {
    text-decoration: none !important;
    color: var(--weak) !important;
}
#contactBtn,
#back {
    width: fit-content;
    display: flex;
    padding: 1.2em 1rem;
    cursor: pointer;
    gap: 0.4rem;
    font-weight: bold;
    border-radius: 30px;

    background: linear-gradient(
            15deg,
            #8c5741,
            #9d674e,
            #a6755c,
            #b68a6b,
            #bd9b7f,
            #d1ad92,
            #e6c9b7,
            #f4e6db,
            #fff
        )
        no-repeat;
    background-size: 300%;
    color: #fff;
    border: none;
    background-position: left center;
    box-shadow: 0 30px 10px -20px rgba(255, 255, 255, 0.2);
    transition: all 0.3s ease;
}

#contactBtn:hover,
#back:hover {
    background-size: 320%;
    background-position: right center;
    transform: translate(0, -10px);
}

#contactBtn:hover svg,
#back:hover svg {
    fill: #fff;
}

#contactBtn svg,
#back svg {
    width: 23px;
    fill: #f09f33;
    transition: 0.3s ease;
}
/* Style the scrollbar */
::-webkit-scrollbar {
    width: 6px; /* Set the width of the scrollbar */
    height: 12px; /* Set the height of the horizontal scrollbar */
}

/* Style the scrollbar track (the area the thumb slides in) */
::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Light grey background for the track */
    border-radius: 10px; /* Rounded corners for the track */
}

/* Style the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
    background-color: #a1755c; /* Darker thumb color */
    border-radius: 10px; /* Rounded corners for the thumb */
}

/* Style the scrollbar thumb when hovered */
::-webkit-scrollbar-thumb:hover {
    background-color: #a1755c; /* Darker thumb color on hover */
}

/* Style the scrollbar corner (where the vertical and horizontal scrollbars meet) */
::-webkit-scrollbar-corner {
    background-color: #a1755c;
}
