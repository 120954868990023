#whyMe {
    width: 100vw;
    min-height: 80vh;
    background-color: var(--highlight_fade);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 200px;
    text-align: center;
}

#whyMe div h1 {
    position: relative;
    font-size: 2.5rem;
    margin-bottom: 10px;
    overflow-x: visible;
}

#whyMe div h1::before {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 58%;
    transform: translateX(-270px);
    width: 150%;
    height: 20px;
    background-image: url("../../media/images/textUnderline.png");
    background-size: contain;
    background-repeat: no-repeat;
}
@media (max-width: 1200px) {
    #whyMe div h1::before {
        transform: translateX(-250px);
    }
}
@media (max-width: 900px) {
    #whyMe div h1::before {
        transform: translateX(-220px);
    }
    #whyMe {
        padding: 50px;
    }
}
@media (max-width: 430px) {
    #whyMe {
        padding: 50px;
    }
    #whyMe div h1::before {
        bottom: -15px;
        height: 15px;
        transform: translateX(-150px);
    }
    #whyMe div h1 {
        font-size: 2rem;
        margin-bottom: 20px;
    }
}
