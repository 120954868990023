header.big_screen {
    width: 100vw;
    height: 100px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: #fff;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
}
header.big_screen * {
    overflow-x: visible;
}
header.big_screen nav ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 25px;
    list-style-type: none;
}
header.big_screen nav ul li {
    width: auto;
    font-size: 1.5rem;
}
header.big_screen nav ul li a {
    color: var(--strong);
    position: relative;
    text-decoration: none;
    transition: all 0.3s ease;
}

header.big_screen nav ul li a::before {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: var(--weak);
    transition: width 0.3s ease;
}
header.big_screen nav ul li a:hover {
    color: var(--highlight);
}
header.big_screen nav ul li a:hover::before {
    width: 100%;
    background-color: var(--highlight);
}

header.big_screen .paste-button {
    position: relative;
    display: block;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

header.big_screen .button {
    background: none;
    color: var(--strong);

    border: none;
    padding: 10px 15px;
    font-weight: bold;
    border-radius: 15px;
    font-size: 1.5rem;
    font-weight: normal;
    cursor: pointer;
    transition: all 0.2s;
}
header.big_screen .button span {
    transition: all 0.2s;
}
header.big_screen .smallNav .button {
    font-size: 1.2rem;
}

header.big_screen .dropdown-content {
    display: none;
    position: absolute;
    z-index: 99999999999999999999999999999999999999999999999999999999999;
    min-width: 150px;
    background-color: var(--weak);
    border: 2px solid var(--highlight);
    border-radius: 0px 15px 15px 15px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

header.big_screen .dropdown-content a {
    padding: 8px 10px;
    text-decoration: none;
    display: block;
    text-align: right;
    font-size: 1rem;
    color: var(--strong);
    transition: 0.1s;
}

header.big_screen .dropdown-content a:hover {
    background-color: var(--highlight);
    color: var(--strong);
}

header.big_screen .dropdown-content a:focus {
    background-color: var(--highlight);
    color: var(--weak);
}

header.big_screen .dropdown-content #top {
    border-radius: 0px 13px 0px 0px;
}

header.big_screen .dropdown-content #bottom {
    border-radius: 0px 0px 13px 13px;
}

header.big_screen .paste-button:hover button {
    border-radius: 15px 15px 0px 0px;
}
header.big_screen .paste-button:hover button span {
    color: var(--highlight);
}

header.big_screen .paste-button:hover .dropdown-content {
    display: block;
}
header.big_screen .dropdown-content a::before {
    height: 0 !important;
    width: 0 !important;
}
header.big_screen .dropdown-content a:hover::before {
    width: 0 !important;
}

header.big_screen > h1 {
    color: var(--highlight);
    letter-spacing: -2px;
}

header.big_screen > button {
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    display: none;
    color: var(--strong);
}

#small_screen {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0; /* Initially, position it off-screen to the right */
    background-color: rgba(0, 0, 0, 0.2);
    transition: all 3s ease-in-out; /* Smooth transition for sliding */
}

/* Define the navigation container */
#small_screen nav {
    width: 70vw;
    height: 100vh;
    background-color: var(--weak);
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding-right: 50px;
    position: relative;
    left: 100%;
    transition: all 3s ease-in-out; /* Smooth transition for sliding */
}

/* Small screen nav items styling */
#small_screen nav ul {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

#small_screen nav ul li {
    padding: 10px;
    font-size: 1rem;
}

/* When the nav should be displayed, move it to the left */
#small_screen nav.active {
    animation: displayNav 0.2s;
    left: 0;
}
#small_screen nav.close {
    animation: closeNav 0.2s;
    left: 100%;
}
@keyframes displayNav {
    0% {
        left: 100%;
    }
    100% {
        left: 0;
    }
}
@keyframes closeNav {
    0% {
        left: 0;
    }
    100% {
        left: 100%;
    }
}
#small_screen nav > button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 2rem;
    transition: all 0.2s;
}
#small_screen nav > button:hover {
    color: red;
}
@media (max-width: 700px) {
    header.big_screen > nav {
        display: none;
    }
    header.big_screen > button {
        display: block;
    }
}
@media (max-width: 400px) {
    header.big_screen > button {
        font-size: 1rem !important;
    }
    header.big_screen > h1 {
        font-size: 1.5rem;
    }
}
