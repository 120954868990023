#gallery {
    width: 100vw;
    min-height: 100vh;
    background-color: var(--weak);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-block: 50px;
}
#gallery > div.isStopDiv {
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    padding: 15px;
}
#gallery > div.isStopDiv button {
    background-color: var(--strong);
    border-radius: 15px;
    padding: 10px;
    color: var(--weak);
    border: none;
    cursor: pointer;
    font-size: 2rem;
}
@media (max-width: 500px) {
    #gallery > div.isStopDiv {
        padding: 10px;
    }
    #gallery > div.isStopDiv button {
        border-radius: 10px;
        padding: 5px;

        font-size: 1rem;
    }
}
#gallery > h1 {
    position: relative;
    font-size: 2.5rem;
    margin-bottom: 10px;
    overflow-x: visible;
}

#gallery > h1::before {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 58%;
    transform: translateX(-80px);
    width: 150%;
    height: 20px;
    background-image: url("../../media/images/textUnderline.png");
    background-size: contain;
    background-repeat: no-repeat;
}
#gallery > div,
#gallery > div > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-block: 50px;
    /* -webkit-mask: linear-gradient(
        90deg,
        transparent,
        white 5%,
        white 95%,
        transparent
    );
    mask: linear-gradient(90deg, transparent, white 5%, white 95%, transparent); */
}
#gallery > div > div {
    align-items: start;
    overflow-x: hidden;
    position: relative;
}
#gallery > div > div h2 {
    font-size: 2rem;
    margin: 10px;
    color: var(--highlight);
}
#gallery[data-scroll="false"] > div > div ul {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}
#gallery[data-scroll="true"] > div > div ul {
    width: max-content;
    display: flex;
    flex-direction: row;
    gap: 20px;
    position: relative;
    list-style-type: none;
    overflow-x: hidden;
    padding: 30px;
    animation: scroll 20s linear infinite;
}
/* #gallery[data-scroll="true"] > div > div:nth-child(2) ul {
    animation: scroll-left 20s linear infinite;
} */
#gallery > div > div ul:hover {
    animation-play-state: paused;
}

#gallery > div > div ul li img {
    width: 500px;
    border-radius: 15px;
    transition: all 0.4s;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}
#gallery > div > div ul li img:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 1);
}
#gallery > div > div > div {
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-evenly;
    gap: 20px;
    padding: 25px;
}
#gallery > div > div > div video {
    width: 400px !important;
    border-radius: 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}
@keyframes scroll {
    to {
        transform: translate(50%);
    }
}
@keyframes scroll-left {
    to {
        transform: translate(-50%);
    }
}

@media (max-width: 1000px) {
    #gallery > div > div ul li img {
        width: 300px;
    }
}
