#recommendations {
    width: 100vw;
    min-height: 80vh;
    background-color: var(--highlight_fade);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    padding-block: 50px;
}

#recommendations > h1 {
    position: relative;
    font-size: 2.5rem;
    margin-bottom: 10px;
    overflow-x: visible;
}

#recommendations > h1::before {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 58%;
    transform: translateX(-200px);
    width: 150%;
    height: 20px;
    background-image: url("../../media/images/textUnderline.png");
    background-size: contain;
    background-repeat: no-repeat;
}
#recommendations > div:nth-child(2) {
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
}
#recommendations > div:nth-child(2) button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 2rem;
    color: var(--strong);
    background-color: #fff;
    border-radius: 5px;
    margin: 10px;
    padding: 5px;
}
#recommendations ul {
    display: flex;
    list-style-type: none;
    overflow-x: hidden;
}
#recommendations[data-scroll="false"] ul {
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 10px;
}
#recommendations[data-scroll="true"] ul {
    width: max-content;
    flex-direction: row;
    gap: 15px;
    overflow-x: hidden;
    position: relative;
    animation: scroll 20s linear infinite;
    padding-block: 100px;
}
#recommendations[data-scroll="true"] ul:hover {
    animation-play-state: paused;
}
#recommendations[data-scroll="true"] > div:not(:nth-child(2)) {
    -webkit-mask: linear-gradient(
        90deg,
        transparent,
        white 20%,
        white 80%,
        transparent
    );
    mask: linear-gradient(
        90deg,
        transparent,
        white 20%,
        white 80%,
        transparent
    );

    overflow: hidden;
}
#recommendations ul li {
    width: 400px;
    min-height: 150px;
    padding: 15px;
    background-color: var(--weak);
    border-radius: 20px;
    z-index: 1;
    transition: all ease 0.4s;
}
#recommendations[data-scroll="true"] ul + ul {
    padding-block: 0;
    padding-block: 50px;
    animation: scroll-right 20s linear infinite;
}
#recommendations ul + ul li {
    width: fit-content;
    min-height: fit-content;
    padding: 0;
    background: none;
}
#recommendations ul + ul li img {
    width: 200px;
    border-radius: 15px;
    transition: all ease 0.4s;
    position: relative;

    z-index: 1;
}
#recommendations ul + ul li img:hover {
    box-shadow: 0 4px 15px rgba(161, 117, 92, 0.6),
        0 8px 30px rgba(161, 117, 92, 0.4), 0 12px 50px rgba(161, 117, 92, 0.2);
}
#recommendations[data-scroll="true"] ul:not(ul + ul) li:hover {
    transform: scale(1.2);
    box-shadow: 0 4px 15px rgba(161, 117, 92, 0.6),
        0 8px 30px rgba(161, 117, 92, 0.4), 0 12px 50px rgba(161, 117, 92, 0.2);
    position: relative;
    z-index: 10;
}

@keyframes scroll {
    to {
        transform: translate(-50%);
    }
}

@keyframes scroll-right {
    to {
        transform: translate(50%);
    }
}
@media (max-width: 800px) {
    #recommendations ul li {
        width: 300px;
        min-height: 100px;
    }
    #recommendations ul li h1 {
        font-size: 1.3rem;
    }
    #recommendations ul li p {
        font-size: 0.9rem;
    }
    #recommendations ul li img {
        width: 80px;
    }
}
