#Sales {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 100px;
    gap: 20px;
    background-color: var(--weak);
}
#Sales ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    list-style-type: none;
    gap: 20px;
    padding: 50px;
}
#Sales ul li img {
    width: 30vw;
    border-radius: 20px;
}
@media (max-width: 800px) {
    #Sales ul {
        flex-direction: column;
    }
    #Sales ul li img {
        width: 90vw;
    }
}
