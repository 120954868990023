#faq {
    background-color: var(--weak);
    text-align: right;
}

.faqs {
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: space-evenly;
    padding: 50px;
}
.faq * {
    color: var(--strong);
}
.faqs > div {
    width: 50vw;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding-right: 100px;
    padding-top: 100px;
    position: relative;
}
.faqs h1 {
    font-family: "IBM_Plex_Sans", "Franklin Gothic Medium", "Arial Narrow",
        Arial, sans-serif;
    font-size: 3rem;
    direction: rtl;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--strong);
    gap: 5px;
}
.faqs h1::before {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 200px;
    width: 300px;
    transform: translateX(-270px);
    height: 15px;
    background-image: url("../../media/images/textUnderline.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.faqs ul {
    width: 50vw;
    display: flex;
    flex-direction: column;
}
.faqs ul li {
    max-height: 65px;
    width: 40vw;
    overflow-y: hidden;
    padding: 50px;
    border-top: 1px solid #000;
    animation: closeFaq 0.6s;
}

.faqs ul li.expanded {
    overflow-y: scroll;

    animation: openFaq 0.6s ease forwards;
}

@keyframes openFaq {
    0% {
        max-height: 65px;
    }

    100% {
        max-height: 200px;
    }
}
@keyframes closeFaq {
    from {
        max-height: 200px;
    }
    to {
        max-height: 65px;
    }
}

.faqs ul li:last-child {
    border-bottom: 1px solid #000;
}
.faqs ul li div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    transform: translateY(-20px);
    margin-bottom: 20px;
}
.faqs ul li h2 {
    font-size: 1.2rem;
}
.faqs ul li * {
    direction: rtl;
}
.faqs ul li p {
    text-align: right;
}
.faqs ul li:last-child div {
    font-size: 1.8rem;
}
.faqs ul li + li + li + li div {
    font-size: 1.8rem;
}
.faqs ul li div button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 2rem;
    overflow-x: visible;
}

.hamburger {
    cursor: pointer;
}

.hamburger input {
    display: none;
}

.hamburger svg {
    /* The size of the SVG defines the overall size */
    height: 2rem;
    /* Define the transition for transforming the SVG */
    transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line {
    fill: none;
    stroke: black;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 3;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line-top-bottom {
    stroke-dasharray: 12 63;
}

.hamburger input:checked + svg {
    transform: rotate(-45deg);
}

.hamburger input:checked + svg .line-top-bottom {
    stroke-dasharray: 20 300;
    stroke-dashoffset: -32.42;
}
@media (max-width: 930px) {
    .faqs {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-block: 100px;
    }

    .faqs ul {
        order: 2;
        width: 90vw;
        display: flex;
        flex-direction: column;
    }
    .faqs ul li {
        width: 90vw;
        padding: 40px;
    }
    .faqs > div {
        width: 100vw;
        min-height: auto;
        height: auto;
        justify-content: end;
        align-items: center;
        padding: 0;
        padding-bottom: 20px;
    }
    .faqs h1::before {
        left: 260px;
        height: 10px;
    }
}
@media (max-width: 560px) {
    .faqs ul li div h2 {
        font-size: 1rem;
    }
}
@media (max-width: 470px) {
    .faqs ul li div h2 {
        font-size: 0.8rem;
    }
}
@media (max-width: 440px) {
    .faqs ul li {
        padding: 50px;
    }
    .faqs ul li h4 {
        font-size: 0.8rem;
    }
}
