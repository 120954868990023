#openSection {
    width: 100vw;
    height: calc(95vh - 100px);
    background-image: url("../../media/images/openImage.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-top: 50px;
    gap: 10px;
    color: var(--weak);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    background-color: var(--strong);

    position: relative;
}
#openSection::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 1;
}

#openSection * {
    position: relative;
    z-index: 2;
}
#openSection h1 {
    font-weight: normal;
    margin-top: 20px;
    font-size: 3rem;
}
@media (max-width: 1200px) {
    #openSection h1 {
        margin-top: 0;
    }
}
@media (max-width: 800px) {
    #openSection h1 {
        font-size: 2rem !important;
    }
    #openSection p {
        font-size: 1rem !important;
    }
}
@media (max-width: 600px) {
    #openSection h1 {
        margin-top: -20px;
    }
}
#openSection p {
    width: 30vw;
    font-size: 1.5rem;
    text-align: center;
    font-weight: normal;
}
#openSection button {
    margin-top: 20px;
}
#openSection i {
    position: absolute;
    bottom: 50px;
    animation: upDown 1s infinite;
}
@keyframes upDown {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(0, 25px);
    }
    100% {
        transform: translate(0, 0);
    }
}
@media (max-width: 900px) {
    #openSection p {
        width: 500px;
    }
}
@media (max-width: 550px) {
    #openSection p {
        width: 300px;
    }
}
