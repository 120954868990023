#contact {
    width: 100vw;
    min-height: 100vh;
    background-image: url("../../media/images/contactBg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding: 50px;
    background-color: #444444;
}
#contact > h1 {
    color: var(--weak);
    font-size: 3rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
#contact > div {
    display: flex;
    flex-direction: row;
}
#contact > div > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#contact > div > div ul {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    list-style-type: none;
    gap: 15px;
}
#contact > div > div ul li {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 15px;
    padding: 15px;
    color: var(--strong);
    gap: 15px;
    overflow: visible;
}
#contact > div > div ul li:nth-child(3) {
    justify-content: center;
}
#contact > div > div ul li i {
    padding: 10px;
    background-color: var(--highlight);
    border-radius: 10px;
    color: var(--weak);
}
/* From Uiverse.io by david-mohseni */
.wrapper {
    display: inline-flex;
    list-style: none;
    height: 120px;
    width: 100%;
    padding-top: 40px;
    font-family: "Poppins", sans-serif;
    justify-content: center;
}

.wrapper .icon {
    position: relative;
    background: #fff;
    border-radius: 50%;
    margin: 10px;
    width: 50px;
    height: 50px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    overflow: visible;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper a {
    color: var(--strong);
}
.wrapper .tooltip {
    position: absolute;
    top: 0;
    font-size: 14px;
    background-color: var(--weak);
    color: var(--weak);
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    overflow: visible;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background: #fff;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip {
    top: -45px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.wrapper .facebook,
.wrapper .facebook .tooltip,
.wrapper .facebook .tooltip::before {
    background: #1877f2;
    color: #fff;
}
.wrapper .whatsapp,
.wrapper .whatsapp .tooltip,
.wrapper .whatsapp .tooltip::before {
    background: #25d366;
    color: #fff;
}
.wrapper .instagram,
.wrapper .instagram .tooltip,
.wrapper .instagram .tooltip::before {
    background: linear-gradient(
        45deg,
        #f58529,
        #feda77,
        #dd2a7b,
        #8134af,
        #515bd4
    );
    background-size: 200% 200%; /* Makes the gradient larger than the element */
    color: #fff;
    transition: all 0.2s;
}

/* Add animation on hover */
.wrapper .instagram:hover,
.wrapper .instagram:hover .tooltip,
.wrapper .instagram:hover .tooltip::before {
    animation: moveGradient 3s infinite; /* Apply the gradient animation */
}

/* Keyframes for the gradient animation */
@keyframes moveGradient {
    0% {
        background-position: 0% 50%; /* Start position */
    }
    50% {
        background-position: 100% 50%; /* Middle position */
    }
    100% {
        background-position: 0% 50%; /* Back to start */
    }
}
@media (max-width: 600px) {
    #contact > div > div ul li h2 {
        font-size: 1rem;
    }
}
@media (max-width: 360px) {
    #contact > div > div ul li h2 {
        font-size: 0.7rem;
    }
    .wrapper .icon {
        width: 30px;
        height: 30px;
        font-size: 0.8rem;
    }
}
