#about {
    width: 100vw;
    min-height: 80vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
    padding-top: 50px;
}
#about > div {
    width: 50%;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    padding: 40px;
}
#about > div h1 {
    position: relative;
    font-size: 2.5rem;
    color: var(--highlight);
}

#about > div h1::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 100%;
    transform: translateX(-15%);
    width: 150%;
    height: 10px;
    background-image: url("../../media/images/textUnderline.png");
    background-size: contain;
    background-repeat: no-repeat;
}

#about > div + div {
    align-items: center;
    justify-content: center;
}
#about > div div:not(.image) {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
#about > div div.image {
    width: 25vw;
    height: 40vw;
    background-image: url("../../media/images/navaImage.jpg");
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
    border-radius: 20px;
}
@media (max-width: 1400px) {
    #about > div h1::before {
        transform: translateX(-18%);
    }
    #about > div div.image {
        width: 30vw;
        height: 45vw;
    }
}
@media (max-width: 1200px) {
    #about > div h1::before {
        transform: translateX(-22%);
    }
    #about > div div.image {
        width: 35vw;
        height: 50vw;
    }
}
@media (max-width: 1000px) {
    #about > div h1::before {
        transform: translateX(-30%);
    }
    #about > div div.image {
        width: 40vw;
        height: 60vw;
    }
}
@media (max-width: 800px) {
    #about > div h1::before {
        transform: translateX(-35%);
    }
}
@media (max-width: 700px) {
    #about > div h1::before {
        transform: translateX(-45%);
    }
}
@media (max-width: 580px) {
    #about {
        flex-direction: column;
        align-items: start;
    }
    #about > div {
        width: 100%;
        min-height: 30vh;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: space-between;
        padding: 40px;
        padding-bottom: 0;
        order: 2;
        gap: 15px;
    }
    #about > div + div {
        order: 1;
    }
    #about > div h1::before {
        transform: translateX(-25%);
    }
}
@media (max-width: 500px) {
    #about > div h1::before {
        transform: translateX(-30%);
    }
}
@media (max-width: 400px) {
    #about > div h1::before {
        transform: translateX(-40%);
    }
}
@media (max-width: 320px) {
    #about > div h1::before {
        transform: translateX(-45%);
    }
}
