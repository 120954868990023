/* accessibility.css */

#accessibility div {
    font-family: Arial, sans-serif;
    padding: 20px;
    color: var(--strong);
    line-height: 1.6;
}

#accessibility h1 {
    font-size: 2.5rem;
    color: var(--highlight);
    margin-bottom: 10px;
}

#accessibility h2 {
    font-size: 1.8rem;
    color: var(--highlight);
    margin-top: 20px;
}

#accessibility a {
    color: var(--highlight);
    text-decoration: none;
}

#accessibility a:hover {
    text-decoration: underline;
}

#accessibility br {
    margin-bottom: 10px;
}

#accessibility p {
    font-size: 1rem;
    color: var(--weak);
    margin-bottom: 10px;
}
#back {
    margin-top: 50px;
}
